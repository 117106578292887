<template>
  <div class="col-md-12"  v-if="Object.keys(requisition).length !== 0">
    <div class="card">
      <div class="card-body">
        <a-steps :current="current" size="small">
          <a-step title="Fuel Requisition Request"  :description="requisition.fuel_requisition_statuses.filter(e => e.status === 'ADMIN_PENDING').length > 0 ? customDate(requisition.fuel_requisition_statuses.find(e => e.status === 'ADMIN_PENDING').created_at) : ''" />
          <a-step title="Admin"  :description="requisition.fuel_requisition_statuses.filter(e => e.status === 'ADMIN_APPROVED').length > 0 ? customDate(requisition.fuel_requisition_statuses.find(e => e.status === 'ADMIN_APPROVED').created_at) : ''" />
          <a-step title="Fuel Collected"  :description="requisition.fuel_requisition_statuses.filter(e => e.status === 'FUEL_COLLECTED').length > 0 ? customDate(requisition.fuel_requisition_statuses.find(e => e.status === 'FUEL_COLLECTED').created_at) : ''" />
        </a-steps>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'requisition_header_status',
  props: ['current', 'requisition'],
  data() {
    return {}
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
  },
}
</script>

<style scoped>
.ant-steps-item-description {
  margin-top: -5px !important;
}
</style>
