<template>
  <div class="row" v-if="Object.keys(requisition).length !== 0">
    <div class="col-md-12 mb-3" v-if="user.role.includes('head_of_admin')">
      <a-button v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && !requisition.fuel_requisition_status.includes('ADMIN_APPROVED') && !requisition.fuel_requisition_status.includes('ADMIN_REJECTED') " :loading="btnAdminApprove" class="btn btn-success btn-sm mr-2 float-right" @click.prevent="adminApproveStatus(requisition.id)"><i class="fa fa-check"></i> Approve</a-button>
      <a-button v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && !requisition.fuel_requisition_status.includes('ADMIN_APPROVED') && !requisition.fuel_requisition_status.includes('ADMIN_REJECTED')" :loading="btnAdminReject" class="btn btn-danger btn-sm mr-2 float-right" @click.prevent="adminRejectStatus(requisition.id)"><i class="fa fa-times"></i> Reject</a-button>
    </div>
    <div class="col-md-12">
      <div class="card">
        <a-spin :spinning="spinning" :delay="delayTime">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <b>Requisition ID: </b> {{ requisition.id }}<br>
                <b>Requisition Status: </b>
                <a-tag color="#f50" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && !requisition.fuel_requisition_status.includes('ADMIN_APPROVED') && !requisition.fuel_requisition_status.includes('ADMIN_REJECTED') ">Pending</a-tag>
                <a-tag color="#f50" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && requisition.fuel_requisition_status.includes('ADMIN_REJECTED')">Rejected</a-tag>
                <a-tag color="#87d068" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && requisition.fuel_requisition_status.includes('ADMIN_APPROVED')">Approved</a-tag>
                <br>
                <b>Requisition Created at : </b> {{ customDate(requisition.created_at) }}<br>
              </div>
              <div class="col-sm-6">
                <b>Hub: </b> {{ requisition.hub.name }}<br>
                <b>Requisition Fuel Status: </b>
                <a-tag color="#f50" v-if="requisition.fuel_collection_status == 'Collect'">Collect</a-tag>
                <a-tag color="#87d068" v-else>Collected</a-tag>
                <br>
                <b>Fuel Requisition Approved at: </b> <span v-if="requisition.fuel_requisition_statuses.filter(e => e.status === 'ADMIN_APPROVED').length > 0">{{ customDate(requisition.fuel_requisition_statuses.find(e => e.status === 'ADMIN_APPROVED').created_at) }}</span>
              </div>
            </div>
          </div>
        </a-spin>
      </div>
    </div>
    <requisition-header-status :current="current" :requisition="requisition"></requisition-header-status>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-hover table-bordered">
            <tr>
              <th>Remarks</th>
              <td>{{ requisition.purpose }}</td>
              <th>Fuel Purpose Type</th>
              <td>{{ requisition.fuel_purpose_type.fuel_purpose_type_desc }}</td>
            </tr>
            <tr>
              <th>Requisite Liter</th>
              <td>{{ requisition.requisite_liter }}</td>
              <th>In Word</th>
              <td>{{ numWords(requisition.requisite_liter) }}</td>
            </tr>
            <tr>
              <th>Last Refill Date</th>
              <td>{{ requisition.last_refill_date }}</td>
              <th>Fuel Type</th>
              <td>{{ requisition.fuel_type.fuel_type_desc }}</td>
            </tr>
            <tr>
              <th>Present Running Hour</th>
              <td>{{ requisition.present_running_hour }}</td>
              <th>Hour Ran During Last Refill</th>
              <td>{{ requisition.hour_ran_during_last_refill }}</td>
            </tr>
            <tr>
              <th>Generator Reading</th>
              <td>
                <a :href="requisition.generator_reading_url" target="_blank" download>
                  <img :src="requisition.generator_reading_url" alt="" class="rounded mx-auto d-block" width="200" height="200"/>
                </a>
              </td>
              <th>Fuel Refilling</th>
              <td>
                <a :href="requisition.fuel_refilling_url" target="_blank" download>
                  <img :src="requisition.fuel_refilling_url" alt="" class="rounded mx-auto d-block" width="200" height="200"/>
                </a>
              </td>
            </tr>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { ToWords } from 'to-words'
import requisitionHeaderStatus from '@/views/fuelRequisition/partial/requisition_header_status'

export default {
  name: 'admin_view',
  components: { requisitionHeaderStatus },
  data() {
    return {
      requisition: {},
      loading: false,
      show: false,
      spinning: false,
      delayTime: 500,
      btnAdminApprove: false,
      btnAdminReject: false,
      current: 0,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.getRequisition()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getRequisition() {
      const fuelRequisitionId = this.$route.params.fuel_requisition_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/hub/fuel-requisition/view/' + fuelRequisitionId)
        .then(response => {
          this.loading = false
          this.spinning = false
          const data = response.data
          this.requisition = data.requisition
          this.current = data.max_fuel_requisition_status
        })
        .catch(error => {
          console.log(error)
        })
    },
    adminRejectStatus(fuelRequisitionId) {
      if (confirm('Do you really want to reject?')) {
        this.btnAdminReject = true
        apiClient.post('api/admin/hub/fuel-requisition/reject', { fuelRequisitionId: fuelRequisitionId }).then(response => {
          this.btnAdminReject = false
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getRequisition()
            }
          } else {
            this.$notification.error({
              message: 'Fuel Requisition Rejected',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    adminApproveStatus(fuelRequisitionId) {
      if (confirm('Do you really want to approve?')) {
        this.btnAdminApprove = true
        apiClient.post('api/admin/hub/fuel-requisition/accept', { fuelRequisitionId: fuelRequisitionId }).then(response => {
          this.btnAdminApprove = false
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getRequisition()
            }
          } else {
            this.$notification.error({
              message: 'Approved Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    numWords(fuelLiter) {
      const toWords = new ToWords()
      return toWords.convert(fuelLiter)
    },
  },
}
</script>

<style scoped>

</style>
